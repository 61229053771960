import React, { createContext, useState, ReactNode } from 'react';
import { Permission } from '../types/types';

// Define your user type
interface Role {
  id: string;
  name: string;
}

interface Group {
  id: string;
  name: string;
}
interface UserUpdate {
  email: string;
  firstName?: string;
  lastName?: string;
  roles: string[];
  group?: string[];
}
interface User {
  isDeleted: boolean;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Role[];
  groups: Group[];
  isSuperAdmin?: boolean;
  name?: string;
  permissions: Permission[];
  groupIds: string[];
  profileImg?: string;
}

// Define context type
interface UserContextType {
  user: User | null;
  users: User[];
  setUser: (_user: User | null) => void;
  setUsers: (_user: User[]) => void;
  isLoading: boolean;
}

const UserContext = createContext<UserContextType>({
  user: null,
  users: [],
  setUser: (_user: User | null) => {},
  setUsers: (_user: User[]) => {},

  isLoading: false,
});

// User provider component
const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Value object to pass into context provider
  const value: UserContextType = {
    user,
    setUser,
    users,
    setUsers,
    isLoading,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext, UserContextType, User, UserUpdate };
export default UserProvider;
