import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Table,
  ColomnProps,
  ActionBtn,
  BreadcrumbNav,
  showToast,
} from 'design-web';
import dayjs from 'dayjs';
import searchImg from '../../../assets/images/search.svg';
import { useTheme } from 'shared/src/hooks/useTheme';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import Modal from 'packages/web/src/components/Modal';
import { Document } from 'shared/contexts/DocumentContext';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import DateRange from '../../../components/DateRange';
import dotAction from '../../../assets/images/dotAction.svg';
import folder from '../../../assets/images/folder-group.svg';
import document from '../../../assets/images/document.svg';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import wordIcon from '../../../assets/images/word-icon.svg';
import excelIcon from '../../../assets/images/excel-icon.svg';
import crossIcon from '../../../assets/images/crossIcon.svg';

import useBreadcrumb from 'shared/hooks/useBreadcrumbs';
import { DocumentForm } from './DocumentForm';
import useDebounce from 'shared/hooks/useDebounce';
import { useDocumentContext } from 'shared/hooks/context/useDocumentContext';
import { getTransformedUrl } from '../../../utils/utils';
import routes from 'shared/config/routes';
import {
  DateRangeType,
  DateType,
  DateValueType,
} from 'react-tailwindcss-datepicker';
import ShareModal from './ShareModal';
import DeleteModal from '../../../components/DeleteModal';
import useAccessControl from 'shared/hooks/useAccessControl';
import { ActionPermissions, DocSharePermissions } from 'shared/types/types';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import noRecords from '../../../assets/images/noRecord.svg';
import { getUserFullName } from 'shared/utils/utils';
import CustomDropdown from 'packages/web/src/components/CustomDropdown';
import { DataTableSortStatus } from 'mantine-datatable';
import { SortingDropdown } from 'packages/web/src/components/SortingDropdown';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

type DateRangeValueType = {
  start: DateType;
  end: DateType;
};

type User = {
  id: number;
  name: string;
  email: string;
  permission?: 'Viewer' | 'Editor' | 'None';
};

export const DocumentList: React.FC = () => {
  // State hooks
  const [dateRange, setDateRange] = useState<DateRangeValueType>({
    start: null,
    end: null,
  });
  const [page, setPage] = useState(1);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [showShareForm, setShowShareForm] = useState(false);
  const [sharedUsers, setSharedUsers] = useState<User[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null
  );
  const [isDropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [showFolderForm, setShowFolderForm] = useState(false);
  const { userInfo } = useAuthContext();
  // Custom hooks
  // Check if the user has permission to create
  const [canCreate, setCanCreate] = useState(
    useAccessControl(ActionPermissions.CREATE)
  );

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const canRoleCreate = useAccessControl(ActionPermissions.CREATE);

  // Check if the user has permission to view
  const canView = useAccessControl(ActionPermissions.VIEW);

  // Check if the user has permission to share
  const canShare = useAccessControl(ActionPermissions.SHARE);

  // Check if the user has permission to edit
  const canEdit = useAccessControl(ActionPermissions.EDIT);

  // Check if the user has permission to download
  const canDownload = useAccessControl(ActionPermissions.DOWNLOAD);

  // Check if the user has permission to delete
  const canDelete = useAccessControl(ActionPermissions.DELETE);

  const { isMobile } = useTheme();
  const { getDocumentList, loading, deleteDocument, downloadDocument } =
    useDocumentService();
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);
  const {
    documents: data,
    setDocuments,
    otherDetails: folderDetail,
  } = useDocumentContext();
  const navigate = useNavigate();
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  const handleAction = (item: Document, action: string) => {
    setSelectedDocument(item);
    setDropdownOpen(null); // Close the dropdown if it's already open
    console.log(`${action} action triggered`);
    // Implement the action handling logic here
    switch (action) {
      case 'rename':
        setShowFolderForm(true);
        break;
      case 'delete':
        setIsDeleteModalVisible(true);
        break;
      case 'download':
        // Download the document
        downloadDocument(item.id, {
          action: 'download',
          isFolder: item.isFolder,
          name: item.name,
        });
        break;
      case 'share':
        setShowShareForm(true);
        break;
      case 'view':
        if (!item.isFolder) {
          navigate(
            `${getTransformedUrl(routes.groupDocumentView, {
              groupId,
              docId: item.id,
            })}?docName=${item.name}`,
            {
              state: {
                document: item.name,
              },
            }
          );
        }
        break;
      default:
        break;
    }
  };

  // get param from route parameters
  const groupId = useParams().groupId;
  const folderId = useParams().folderId || null;

  const isSharedGroup = () => {
    return (
      !userInfo?.groupIds.includes(groupId || '') && !userInfo?.isSuperAdmin
    );
  };

  const fetchData = async (
    currentPage = page,
    sortingData: any = sortStatus
  ) => {
    if (!groupId) return;
    try {
      const queryPayload = {
        page: currentPage,
        pageSize: 50,
        searchTerm: debounceVal,
        dateRange,
        isShared: isSharedGroup() ? true : '',
        // sorting params
        sortBy: sortingData.columnAccessor,
        sortOrder: sortingData.direction,
      };
      const documents =
        (await getDocumentList(groupId, folderId, queryPayload)) || [];

      if (currentPage === 1) {
        setDocuments(documents);
      } else {
        setDocuments([...data, ...documents]);
      }

      // Only increment the page if we fetched a full batch
      if (documents.length === 50) {
        setPage(currentPage + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Use the hook
  useInfiniteScroll(lastElementRef, { threshold: 0.5 }, () => {
    if (data.length % 50 === 0) {
      // Ensure we don't fetch if the last batch wasn't full
      fetchData(page);
    }
  });

  useEffect(() => {
    if (userInfo?.isSuperAdmin) return;
    setCanCreate(false);
    if (isSharedGroup() && !folderId) {
      setCanCreate(false);
    }

    if (isSharedGroup() && folderId && isSharedCreate()) {
      setCanCreate(true);
    }
    if (canRoleCreate && !isSharedGroup()) {
      setCanCreate(true);
    }

    if (folderId && userInfo?.id === folderDetail?.userId) {
      setCanCreate(true);
    }
  }, [folderDetail]);

  useEffect(() => {
    resetDocumentScreen();
    fetchData(1);
  }, [debounceVal, folderId, dateRange]);

  const loadMoreRecords = () => {
    if (!loading) fetchData();
  };

  const handleDeleteDocument = async () => {
    if (!selectedDocument?.id) return;
    try {
      await deleteDocument(selectedDocument.id);
      setDocuments(data.filter(item => item.id !== selectedDocument.id));
      setIsDeleteModalVisible(false);
      setSelectedDocument(null);
      showToast(
        'success',
        `${
          selectedDocument.isFolder ? 'Folder' : 'Document'
        } Deleted Successfully.`
      );
    } catch (error: any) {
      console.error(error);
      showToast('error', error.response.data.message);
    } finally {
      setDropdownOpen(null); // Close the dropdown if it's already open
    }
  };

  const columns: ColomnProps[] = [
    {
      key: 'name',
      title: 'FOLDERS/DOCUMENTS',
      textAlign: 'left',
      sortable: true,
    },
    {
      key: 'createdAt',
      title: 'CREATED DATE',
      textAlign: 'left',
      sortable: true,
    },
    { key: 'createdBy', title: 'CREATED BY', textAlign: 'left' },
    // { key: 'description', title: 'DESCRIPTION', textAlign: 'left' },
    { key: 'action', title: 'Action', textAlign: 'left' },
  ];

  const isSharedCreate = () => {
    return (
      isSharedGroup() &&
      folderDetail?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR &&
      (canRoleCreate || folderDetail?.userId === userInfo?.id)
    );
  };

  const getActionMenuTemplate = (item: Document) => {
    const canEditDocument = canEdit || item.userId === userInfo?.id;
    const canShareDocument = canShare || item.userId === userInfo?.id;
    const canDeleteDocument = canDelete || item.userId === userInfo?.id;
    const canDownloadDocument =
      canDownload || item.userId === userInfo?.id || canView;

    // Prepare action menus based on permissions
    // const isSharedEdit =
    //   !isSharedGroup() ||
    //   item.userId === userInfo?.id ||
    //   (item?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR &&
    //     item?.DocumentShare?.[0]?.documentId === item.id);

    // const isShareDelete =
    //   !isSharedGroup() || (isSharedEdit && item.userId === userInfo?.id);

    const isSharedEdit =
      !isSharedGroup() ||
      (isSharedGroup() &&
        item?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR &&
        canEditDocument);

    const isSharedAllowed =
      !isSharedGroup() ||
      (isSharedGroup() &&
        item?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR);

    const isSharedDownload =
      !isSharedGroup() ||
      (isSharedGroup() && item?.DocumentShare?.[0]?.documentId);

    const actionMenus = [
      {
        label: 'Rename',
        action: () => handleAction(item, 'rename'), // Pass a function instead of a string
        allow: canEditDocument && isSharedEdit,
      },
      {
        label: 'Download',
        action: () => handleAction(item, 'download'), // Function
        allow: canDownloadDocument && isSharedDownload,
      },
      {
        label: 'Delete',
        action: () => handleAction(item, 'delete'), // Function
        allow: canDeleteDocument && isSharedAllowed,
      },
      {
        label: 'Share',
        action: () => handleAction(item, 'share'), // Function
        allow: canShareDocument && isSharedAllowed,
      },
      {
        label: 'View',
        action: () => handleAction(item, 'view'), // Function
        allow: canView && !item.isFolder,
      },
    ];

    // Filter allowed actions
    const allowedActions = actionMenus.filter(menu => menu.allow);
    return allowedActions;
  };

  const navigateToFolderAndDocs = (folder: Document) => {
    if (!canView && !folder.isFolder) return;
    if (folder.isFolder) {
      navigate(
        getTransformedUrl(routes.groupFoldersDocuments, {
          groupId,
          folderId: folder.id,
        }),
        {
          state: {
            folderName: folder.name,
          },
        }
      );
    } else {
      // Navigate to the DocumentView component
      navigate(
        `${getTransformedUrl(routes.groupDocumentView, {
          groupId,
          docId: folder.id,
        })}?docName=${folder.name}`,
        {
          state: {
            document: folder.name,
          },
        }
      );
    }
  };

  const resetDocumentScreen = () => {
    setPage(1);
    setDocuments([]);
  };

  const getDocumentIcon = (fileName: any) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return pdfIcon;
      case 'doc':
      case 'docx':
        return wordIcon;
      case 'xls':
      case 'xlsx':
        return excelIcon;
      default:
        return document;
    }
  };

  const tableData = data?.map((item, index) => ({
    name: (
      <div className="flex items-center gap-x-3 py-2">
        <div
          className={`flex flex-row gap-3 justify-center ${
            canView || item.isFolder ? 'cursor-pointer' : ''
          }`}
          onClick={() => navigateToFolderAndDocs(item)}
        >
          <img
            src={item.isFolder ? folder : getDocumentIcon(item.name)}
            alt={item.isFolder ? 'Folder Icon' : 'Document Icon'}
            className="w-8 h-8"
          />
          <span
            key={index}
            className="whitespace-normal break-words mt-2 font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base"
            style={{ maxWidth: '250px' }}
          >
            {item.name}
          </span>
        </div>
      </div>
    ),
    createdAt: (
      <div className="flex items-center gap-x-3 py-2">
        <span className="font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base">
          {dayjs(item.createdAt).format('MMMM D, YYYY h:mm A')}
        </span>
      </div>
    ),
    createdBy: (
      <div className="flex items-center gap-x-3 py-2">
        <span className="font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base">
          {getUserFullName(item.user)}
        </span>
      </div>
    ),
    description: (
      <div className="flex items-center gap-x-3 py-2">
        <span className="font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base">
          {item.description}
        </span>
      </div>
    ),
    action:
      (getActionMenuTemplate(item).length > 0 && (
        <CustomDropdown
          trigger={
            <span className="py-2 text-center cursor-pointer">
              <img src={dotAction} alt="Dot Action" className="w-4 h-4" />
            </span>
          }
          items={getActionMenuTemplate(item)}
        />
      )) ||
      '',
  }));

  // get action menue template

  const handleAddFolder = () => {
    setShowFolderForm(true);
    setSelectedDocument(null);
  };

  const handleAddDocument = () => {
    // setShowDocUploadForm(true);
    // navigate to upload document route
    navigate(
      getTransformedUrl(
        folderId ? routes.uploadFolderDocuments : routes.uploadGroupDocuments,
        {
          groupId,
          folderId: folderId,
        }
      ),
      {}
    );
  };

  useEffect(() => {
    setDropdownOpen(null);
  }, [data]);

  const handleSearchDocument = (searchText: string) => {
    resetDocumentScreen();
    // fetch data with search term
    setCurrentValue(searchText);
  };

  const handleDateRangeChange = (selectedDateRange: DateValueType) => {
    const start = selectedDateRange?.startDate
      ? new Date(selectedDateRange.startDate)
      : null;
    const end = selectedDateRange?.endDate
      ? new Date(new Date(selectedDateRange.endDate).setHours(23, 59, 59, 999))
      : null;

    setDateRange({
      start: start,
      end: end,
    });
  };

  const getDocumentDeleteMsg = (document: Document) => {
    return (
      <div>
        <p>This action cannot be undone.</p>
        <p>{`Are you sure you want to delete this ${
          document?.isFolder ? 'Folder' : 'Document'
        }`}</p>
      </div>
    );
  };

  const handleSort = (data: DataTableSortStatus) => {
    setSortStatus(data);
    fetchData(1, data);
  };

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 py-4 container">
        <div className="w-full">
          <BreadcrumbNav className="font-inter text-sm font-bold text-gray-500 leading-[15px] text-left mb-6" />
        </div>
        <div className="items-center justify-between md:flex">
          <div className="max-w-lg flex items-center justify-between">
            <h3 className="font-inter text-2xl font-semibold leading-[29.26px] text-left mb-8 md:mb-0">
              Documents
            </h3>
            {isMobile && canCreate && (!isSharedGroup() || isSharedCreate()) && (
              <div>
                <CustomDropdown
                  trigger={
                    <span className="py-2 text-center cursor-pointer">
                      <ActionBtn
                        placement="bottom-end"
                        btnClassName="py-2 px-8 mb-8 text-semiBase md:text-xl font-bold
                text-white focus:outline-none rounded-lg border
                border-gray-200 bg-primary-lightBlue hover:bg-primary-lightBlue focus:z-10
                focus:ring-4 focus:ring-gray-200 text-center"
                        button={<>+ Add</>}
                      />
                    </span>
                  }
                  items={[
                    {
                      label: 'Add Folder',
                      action: handleAddFolder,
                    },
                    {
                      label: 'Upload Document',
                      action: handleAddDocument,
                    },
                  ]}
                />
              </div>
            )}
          </div>

          <div className="flex items-center">
            <div className="relative flex items-center w-full md:w-auto mr-0 lg:mr-4 mb-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <img src={`${searchImg}`} alt="Search image" className="" />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full text-[15px] font-normal text-left py-3 pl-10 px-6 text-gray-900 border
        border-gray-300 rounded-xl bg-white focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for Document"
                onChange={e => handleSearchDocument(e.target.value)}
              />
            </div>

            {/* // date range filter */}

            {!isMobile && (
              <>
                <div className="sm:w-60 w-full h-12 mr-1 lg:mr-3 mb-1 lg:mb-3">
                  <DateRange onDateSelect={handleDateRangeChange} />
                </div>
                {canCreate && (!isSharedGroup() || isSharedCreate()) && (
                  <div>
                    <CustomDropdown
                      trigger={
                        <span className="py-2 text-center cursor-pointer">
                          <ActionBtn
                            placement="bottom-end"
                            btnClassName="btn btn-primary dropdown-toggle py-2 px-10 mr-2 mb-2 text-semiBase md:text-xl font-normal
        text-white focus:outline-none rounded-xl border
        border-gray-200 bg-primary-lightBlue hover:bg-blue-500 focus:z-10
        focus:ring-4 focus:ring-gray-200 text-center whitespace-nowrap"
                            button={<>+ Add</>}
                          />
                        </span>
                      }
                      items={[
                        {
                          label: 'Add Folder',
                          action: handleAddFolder,
                        },
                        {
                          label: 'Upload Document',
                          action: handleAddDocument,
                        },
                      ]}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {isMobile && (
            <div className="w-full border rounded-xl">
              <DateRange onDateSelect={handleDateRangeChange} />
            </div>
          )}

          {isMobile && (
            <div className="flex w-full mt-2 space-x-2">
              {/* Sort By Dropdown */}
              <SortingDropdown
                label="Sort By"
                value={sortStatus.columnAccessor}
                options={[
                  { value: 'name', label: 'Name' },
                  { value: 'createdAt', label: 'Date' },
                ]}
                onChange={selectedColumn => {
                  // When Sort By changes, set direction to 'asc'
                  handleSort({
                    columnAccessor: selectedColumn,
                    direction: 'asc',
                  });
                }}
              />

              {/* Sort Order Dropdown */}
              <SortingDropdown
                label="Sort Order"
                value={sortStatus.direction}
                options={[
                  { value: 'asc', label: 'Ascending' },
                  { value: 'desc', label: 'Descending' },
                ]}
                onChange={selectedOrder => {
                  // Ensure the direction is correctly typed
                  handleSort({
                    columnAccessor: sortStatus.columnAccessor,
                    direction: selectedOrder as 'asc' | 'desc',
                  });
                }}
              />
            </div>
          )}
        </div>

        <div className="shadow-md border rounded-2xl overflow-x-auto bg-white mt-4">
          {isMobile ? (
            <div className="flex flex-col">
              {loading ? ( // Conditionally show loader when loading is true
                <div className="flex justify-center items-center py-4">
                  {<DataSaveLoader visible={true} />}
                </div>
              ) : data.length === 0 ? ( // Check if there is no data
                <div className="flex justify-center items-center py-16">
                  <div>
                    <img
                      src={`${noRecords}`}
                      className="w-16 h-16"
                      alt="Empty state"
                    />
                    <p className="text-gray-500">No records</p>
                  </div>
                </div>
              ) : (
                <div>
                  {data.map((item, idx) => (
                    <div
                      key={idx}
                      ref={idx === data.length - 1 ? lastElementRef : null}
                      className="bg-white p-4 border flex flex-col"
                    >
                      <div className="flex items-center">
                        <div className="flex flex-col w-full gap-2 p-2">
                          <div className="flex justify-between items-center">
                            <div
                              className="flex items-center gap-3"
                              onClick={() => navigateToFolderAndDocs(item)}
                            >
                              <img
                                src={
                                  item.isFolder
                                    ? folder
                                    : getDocumentIcon(item.name)
                                }
                                alt={
                                  item.isFolder
                                    ? 'Folder Icon'
                                    : 'Document Icon'
                                }
                                className="w-8 h-8"
                              />
                              <span className="text-lg font-medium break-all">
                                {item.name}
                              </span>
                            </div>
                            {getActionMenuTemplate(item).length > 0 && (
                              <span className="relative cursor-pointer mr-2">
                                <CustomDropdown
                                  trigger={
                                    <span className="py-2 text-center cursor-pointer">
                                      <img
                                        src={dotAction}
                                        alt="Dot Action"
                                        className="w-4 h-4"
                                      />
                                    </span>
                                  }
                                  items={getActionMenuTemplate(item)}
                                />
                              </span>
                            )}
                          </div>
                          <div className="flex justify-between mt-2 text-black break-all">
                            <span className="font-inter text-semiBase font-medium leading-[16.94px] text-left">
                              <span className="text-gray-500">Created By:</span>{' '}
                              {getUserFullName(item.user)}
                            </span>
                            <span className="font-inter text-semiBase font-medium ml-2 leading-[16.94px] text-right whitespace-nowrap">
                              {dayjs(item.createdAt).format('DD/MM/YYYY')}
                            </span>
                          </div>
                          {/* <div className="font-inter text-semiBase font-medium leading-[16.94px] text-left mt-2">
                        <span className="text-gray-500">Description:</span>{' '}
                        {item.description}
                      </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className={`mx-auto mt-8}`}>
              <Table
                data={tableData}
                coloums={columns}
                loadMoreRecords={loadMoreRecords}
                infiniteScrolling={true}
                loadingData={!!loading}
                // sorting callback
                onSort={handleSort}
              />
            </div>
          )}
        </div>
      </div>

      {/* DeleteModal Component */}
      {selectedDocument && (
        <DeleteModal
          title={`Delete ${selectedDocument?.isFolder ? 'Folder' : 'Document'}`}
          isOpen={isDeleteModalVisible}
          loading={loading}
          onClose={() => setIsDeleteModalVisible(false)}
          onDelete={handleDeleteDocument}
          body={getDocumentDeleteMsg(selectedDocument)}
          primaryBtnTxt="Delete"
        />
      )}

      {/* Share Modal */}
      {selectedDocument && (
        <ShareModal
          isOpen={showShareForm}
          onClose={() => setShowShareForm(false)}
          document={selectedDocument}
          title={`Share "${selectedDocument.name}"`}
          loading={loading}
        />
      )}

      {showFolderForm && (
        <DocumentForm
          type={
            selectedDocument
              ? selectedDocument.isFolder
                ? 'folder'
                : 'doc'
              : 'folder'
          }
          setIsModalVisible={setShowFolderForm}
          editDocument={selectedDocument}
          setEditDocument={setSelectedDocument}
        />
      )}
    </>
  );
};
