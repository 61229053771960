const HttpMethod = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
} as const;

export default {
  UPLOAD: {
    IMAGE: { method: HttpMethod.POST, url: '/upload/images' },
    FILE: { method: HttpMethod.POST, url: '/upload/files' },
  },
  AUTH: {
    INFO: { method: HttpMethod.GET, url: '/auth/info' },
    LOGIN: { method: HttpMethod.POST, url: '/auth/login' },
    REGISTER: { method: HttpMethod.POST, url: '/auth/register' },
    FORGOT_PASSWORD: { method: HttpMethod.POST, url: '/auth/forgot_password' },
  },
  USER: {
    CREATE: { method: HttpMethod.POST, url: '/users' },
    UPDATE: (userId: string) => ({
      method: HttpMethod.PUT,
      url: `/users/${userId}`,
    }),

    UPDATE_PROFILE: (userId: string) => ({
      method: HttpMethod.PATCH,
      url: `/users/${userId}/profile`,
    }),

    UPDATE_IMAGE: () => ({
      method: HttpMethod.POST,
      url: `/users/upload/get_pre_signed_upload_url`,
    }),

    DELETE: (userId: string) => ({
      method: HttpMethod.DELETE,
      url: `/users/${userId}`,
    }),
    GET_ALL: { method: HttpMethod.GET, url: '/users/list' },
    GET_ALL_SHARE: { method: HttpMethod.GET, url: '/users/share-list' },
    GET: () => ({
      method: HttpMethod.GET,
      url: `/users/`,
    }),
    ACTIVATE: (userId: string) => ({
      method: HttpMethod.PUT,
      url: `/users/${userId}/activate`,
    }),
  },
  ROLE: {
    CREATE: { method: HttpMethod.POST, url: '/role' },
    UPDATE: (roleId: string) => ({
      method: HttpMethod.PUT,
      url: `/role/${roleId}`,
    }),
    DELETE: (userId: string) => ({
      method: HttpMethod.DELETE,
      url: `/role/${userId}`,
    }),
    GET_ALL: { method: HttpMethod.GET, url: '/role/' },
    GET: () => ({
      method: HttpMethod.GET,
      url: `/role/`,
    }),
  },
  GROUP: {
    CREATE: { method: HttpMethod.POST, url: '/groups' },
    UPDATE: (groupId: string) => ({
      method: HttpMethod.PUT,
      url: `/groups/${groupId}`,
    }),
    PATCH: (groupId: string) => ({
      method: HttpMethod.PATCH,
      url: `/groups/${groupId}`,
    }),
    DELETE: (groupId: string) => ({
      method: HttpMethod.DELETE,
      url: `/groups/${groupId}`,
    }),
    GET_ALL_DOCUMENTS: (groupId: string) => ({
      method: HttpMethod.GET,
      url: `/groups/${groupId}/documents`,
    }),
    GET_ALL: { method: HttpMethod.GET, url: '/groups/' },
    GET: () => ({
      method: HttpMethod.GET,
      url: `/groups/`,
    }),
  },
  DOCUMENT: {
    CHECK_FILE_NAMES: { method: HttpMethod.POST, url: '/document/file-check' },
    CREATE: { method: HttpMethod.POST, url: '/document' },
    UPDATE: (documentId: string) => ({
      method: HttpMethod.PUT,
      url: `/document/${documentId}`,
    }),
    UPLOAD: () => ({
      method: HttpMethod.POST,
      url: `/document/upload/get_pre_signed_upload_url`,
    }),
    PATCH: (documentId: string) => ({
      method: HttpMethod.PATCH,
      url: `/document/${documentId}`,
    }),
    DELETE: (documentId: string) => ({
      method: HttpMethod.DELETE,
      url: `/document/${documentId}`,
    }),
    DELETE_VERSION: (documentId: string, versionId: string) => ({
      method: HttpMethod.DELETE,
      url: `/document/${documentId}/version/${versionId}`,
    }),
    GET_ALL: (parentId: string) => ({
      method: HttpMethod.GET,
      url: `/document/${parentId}`,
    }),

    DETAIL: (documentId: string) => ({
      method: HttpMethod.GET,
      url: `/document/${documentId}/detail`,
    }),

    VERSION: (documentId: string) => ({
      method: HttpMethod.GET,
      url: `/document/${documentId}/versions`,
    }),

    DOWNLOAD: (documentId: string) => ({
      method: HttpMethod.GET,
      url: `/document/${documentId}/download`,
    }),
    SHARE: (documentId: string) => ({
      method: HttpMethod.POST,
      url: `/document/${documentId}/share`,
    }),
    SHARE_UPDATE: (documentId: string) => ({
      method: HttpMethod.PATCH,
      url: `/document/${documentId}/share`,
    }),
    CREATE_VERSION: (documentId: string) => ({
      method: HttpMethod.POST,
      url: `/document/${documentId}/createVersion`,
    }),
    GET_SHARED_USERS: (documentId: string) => ({
      method: HttpMethod.GET,
      url: `/document/${documentId}/share`,
    }),
  },
  PERMISSION: {
    CREATE: { method: HttpMethod.POST, url: '/permission' },
    UPDATE: {
      method: HttpMethod.PUT,
      url: `/permission`,
    },
    DELETE: (userId: string) => ({
      method: HttpMethod.DELETE,
      url: `/permission/${userId}`,
    }),
    GET_ALL: { method: HttpMethod.GET, url: '/permission/' },
    GET: () => ({
      method: HttpMethod.GET,
      url: `/permission/`,
    }),
  },
} as const;
